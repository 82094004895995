<template>
	<el-dialog :title="params.title" :model-value="params.show" @close="onCacel" width="800px" center>


		<el-form :model="form" label-width="300px" class="ruleForm">
			<el-form-item label="维修方式" prop="maintenanceMethod">
				<el-select v-model="form.maintenanceMethod" placeholder="请选择维修方式" style="width: 100%"
					@change="changeMethods">
					<el-option v-for="item in global.dict.MaintenanceMethod" :key="item" :label="item" :value="item">
					</el-option>
				</el-select>
			</el-form-item>

 <template v-if="form.maintenanceMethod=='远程'">
        <el-form-item label="派单给工程师" prop="woRepairEngineerName">
          <el-select v-model="form.woRepairEngineerId" placeholder="请选择派单给工程师" style="width: 100%" @change="changeEngineer" filterable>
            <el-option key="请选择" label="请选择" value=""/>
            <el-option v-for="item in engineerNameList" :key="item.id" :label="item.realNamer" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        </template>
        
        <template v-if="form.maintenanceMethod=='现场'">
        <el-form-item label="派单给工程师" prop="engineerNameList">
					<SelectLinkAllData placeholder="请选择派单给工程师" :selected="selectedArr" :options="engineerNameList" field="realNamer" @change="changeInspectors"></SelectLinkAllData>
				</el-form-item>
        </template>

			<el-form-item>
				<el-button type="primary" @click="submitForm('form')">保存</el-button>
				<el-button @click="onCacel()">返回</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import axios from '@/utils/axios'
	import {
		ElMessage
	} from 'element-plus'
	import SelectLink from "@/components/SelectLink"
	import SelectLinkAllData from "@/components/SelectLinkAllData"

	export default {
		// name: "Form",
		// 继承父窗体列表页的数据
		props: {
			params: Object
		},
		data() {

			// 搜索区内容


			return {
				selectedArr: [],
				  selectedArrAll: [],
				//派单工程师的下拉选数组
				engineerNameList: [],
				searchData: {},
				//派单工程师url
      engierUrl: '',
				// 全局变量，用于获得配置信息等
				global: window.global,

				// 更多选项
				option: {

				},
				// 验证选项
				rules: {},
			};

		},
		components: {
			SelectLink,SelectLinkAllData
		},

		computed: {
			// 由于子组件不能修改继承来的对象，所以重新复制一份
			form: function() {
				// this.$nextTick(()=>{
				// 	this.$refs['form'].clearValidate() // 只清除清除验证
				// })

				return this.params.data
			},
		},
		created() {

		},
		// 获取列表
		getTableData() {
			this.loading = true
			var that = this
			axios.get('/Api/WrokOrderAccept/MethodCorrEngList', {
				params: Object.assign({}, this.searchData, this.pageData)
			}).then(res => {
				console.log("获取列表返回的信息", res);
				// that.tableData = res.data.list
				// that.pageData.total = res.data.total
				// that.loading = false
			}).catch(error => {
				that.loading = false
			})
		},
		// 父组件中继承过来的事件
		emits: ["onClose", "getTableData"],
		methods: {

			//维修方式改变
    changeMethods(val) {
		var that = this 
      console.log("改变的维修方式",val)
      if(val == '现场') {
        this.selectedArr = []
      }
      console.log("this.form.maintenanceMethod",this.form.maintenanceMethod)
      axios.get('/WrokOrderAccept/MethodCorrEngList?currentState='+val).then((res) => {
        console.log("改变的维修方式返回的结果", res)
		if(res.data.list.length>0){
			that.engineerNameList = res.data.list;
			if(that.form.woRepairEngineerId != undefined){
				that.form.woRepairEngineerId = ''
			}
		}else{
			that.engineerNameList = []
			if(that.form.woRepairEngineerId != undefined){
				that.form.woRepairEngineerId = ''
			}
		}
		 console.log("this.engineerNameList", this.engineerNameList)
      })
    },
	//选择多个现场工程师
    changeInspectors(arr) {
      console.log("arr",arr)
      this.selectedArrAll = arr;
      arr.map(item => {
         this.selectedArr.push(arr.value);
      })
     
    },
	//改变工程师
    changeEngineer(item) {
      console.log("远程选择工程师时的item",item)
    
    },
			// 提交事件
			submitForm(formName) {
				var that = this
				if (this.params.type == 'add') {
					that.form.engineerNameList = that.selectedArrAll;
					axios.post('/WrokOrderAccept/Create', this.form).then(() => {
						ElMessage.success('添加成功')

						that.$emit('onClose')
						that.$emit('getTableData')
					})
				} else {
					console.log("gongchengshi id::", this.form.id)

					console.log("form  ::", JSON.stringify(this.form))
					that.form.engineerNameList = that.selectedArrAll;
					axios.post('/WrokOrderAccept/AssignEng?id=' + this.form.id, this.form).then((res) => {
						console.log("接收的数据11111",res)
						if(res.data == 'OK') {
							ElMessage.success('指派成功')
							that.$emit('onClose')
							that.$emit('getTableData')
						}else {
							ElMessage.success('指派失败')
							that.$emit('onClose')
							that.$emit('getTableData')
						}

					})
				}

			},
			// 返回事件
			onCacel() {
				this.$emit('onClose')
			}
		}
	}
</script>
