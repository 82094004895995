<template>
	<el-dialog :title="params.title" :model-value="params.show" @close="onCacel" width="800px" center>
		<el-form :model="form" label-width="120px" class="ruleForm">
			<div style="display: grid; grid-template-columns: repeat(2, 50%);">

				<el-form-item label="工单编号" prop="woCode">
					<el-input :modelValue="form.woCode" readonly></el-input>
				</el-form-item>

				<el-form-item label="报修类型" prop="woType">
					<el-input :modelValue="form.woType" readonly></el-input>
				</el-form-item>

				<el-form-item label="工单流转状态" prop="woStatus">
					<el-input :modelValue="form.woStatus" readonly></el-input>
				</el-form-item>

				<el-form-item label="建单时间" prop="woCreateTime">
					<el-input :modelValue="form.woCreateTime" readonly></el-input>
				</el-form-item>

				<el-form-item label="报修项目" prop="repairProject">
					<el-input :modelValue="form.repairProject" readonly></el-input>
				</el-form-item>

				<el-form-item label="报修描述" prop="woDescribe" style="grid-column: 1 / 3;">
					<el-input type="textarea" :rows="10" :modelValue="form.woDescribe" readonly></el-input>
				</el-form-item>

				<el-form-item label="客户名称" prop="compName">
					<el-input :modelValue="form.compName" readonly></el-input>
				</el-form-item>

				<el-form-item label="客户担当" prop="contactPerson">
					<el-input :modelValue="form.contactPerson" readonly></el-input>
				</el-form-item>

				<el-form-item label="报修人" prop="telName">
					<el-input :modelValue="form.telName" readonly></el-input>
				</el-form-item>

				<el-form-item label="手机号码" prop="telphone">
					<el-input :modelValue="form.telphone" readonly></el-input>
				</el-form-item>

				<el-form-item label="报修地点" prop="compAddress">
					<el-input :modelValue="form.compAddress" readonly></el-input>
				</el-form-item>

				<el-form-item label="调度员姓名" prop="woDispatcherName">
					<el-input :modelValue="form.woDispatcherName" readonly></el-input>
				</el-form-item>
				<!-- <el-divider></el-divider><el-divider></el-divider> -->




				<!--        待维修阶段开始显示-->

				<template v-for="(item,index) in form.woaList" :key="index">
					<el-divider></el-divider>
					<el-divider></el-divider>
					<el-form-item label="派单时间" prop="woDisTime">
						<el-input :modelValue="item.woDisTime" readonly></el-input>
					</el-form-item>

					<el-form-item label="维修工程师" prop="woRepairEngineerName">
						<el-input :modelValue="item.woRepairEngineerName" readonly></el-input>
					</el-form-item>

					<el-form-item label="维修方式" prop="maintenanceMethod">
						<el-input :modelValue="item.maintenanceMethod" readonly></el-input>
					</el-form-item>

					<el-form-item label="维修/诊断内容" prop="woRepairContent" style="grid-column: 1 / 3;">
						<el-input type="textarea" :rows="10" :modelValue="item.woRepairContent" readonly></el-input>
					</el-form-item>

					<template v-if="item.maintenancePicture.length!=0 ">
						<el-form-item label="维修照片" style="grid-column: 1 / 3;">
							<div v-for="(item) in item.maintenancePicture" :key="item"
								style="float: left; max-width: 25%;max-height: 100%;margin:auto;left: 100px;right: 0;top: 0;bottom: 0;">
								<img :src="item.fileUrl" alt="" @click="showpic(item.fileUrl)"
									style="max-width: 50%;max-height: 50%;margin: auto;left: 0;right: 0;top: 0;bottom: 0;">
							</div>

						</el-form-item>
					</template>


					<el-form-item label="维修时间" prop="woRepairTime">
						<el-input :modelValue="item.startReairTime" readonly></el-input>
					</el-form-item>

					<el-form-item label="指派调度员" prop="woDispatcherName">
						<el-input :modelValue="item.woDispatcherName" readonly></el-input>
					</el-form-item>
					<template v-if="item.suspendReason!=null ">
						<el-form-item label="挂起原因" prop="suspendReason">
							<el-input :modelValue="item.suspendReason" readonly></el-input>
						</el-form-item>
						<br />
					</template>
				</template>

				<template v-if="form.woStatus=='已关单'">
					<el-divider></el-divider>
					<el-divider></el-divider>
					<el-form-item label="关单时间" prop="woCloseTime">
						<el-input :modelValue="form.woCloseTime" readonly></el-input>
					</el-form-item>

					<el-form-item label="关单调度员" prop="woCloseDisName">
						<el-input :modelValue="form.woCloseDisName" readonly></el-input>
					</el-form-item>

					<el-form-item label="移动时间" prop="movingTime">
						<el-input :modelValue="form.movingTime+'分钟'" readonly></el-input>
					</el-form-item>

					<el-form-item label="对应时间" prop="correspondingTime">
						<el-input :modelValue="form.correspondingTime+'分钟'" readonly></el-input>
					</el-form-item>
				</template>
			</div>
			<el-dialog title="查看图片" v-model="dialogVisible" width="600">
				<span style="display: block;width: 100%;height: 300px;position: relative;">
					<img :src="imgUrl" alt
						style="max-width: 100%;max-height: 100%;margin: auto;position: absolute;left: 0;right: 0;top: 0;bottom: 0;" />
				</span>
			</el-dialog>
			<el-form-item>
				<el-button @click="onCacel()">返回</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import axios from '@/utils/axios'
	import {
		ElMessage
	} from 'element-plus'


	export default {

		//name: "Form",
		// 继承父窗体列表页的数据
		props: {
			params: Object
		},
		data() {
			return {
				dialogVisible: false,
				// 全局变量，用于获得配置信息等
				global: window.global,

				// 更多选项
				option: {

				},
				// 验证选项
				rules: {},
			};
		},
		components: {

		},
		computed: {
			// 由于子组件不能修改继承来的对象，所以重新复制一份
			form: function() {
				var formData = this.params.data

				return formData
			}
		},
		created() {

		},

		// 父组件中继承过来的事件
		emits: ["onClose", "getTableData"],
		methods: {

			// 返回事件
			onCacel() {
				this.$emit('onClose')
			},
			showpic(img) {
				this.imgUrl = img
				this.dialogVisible = true
			}
		}
	}
</script>
